import type { ReactNode } from 'react';

import { Flex, type FlexProps } from '@mantine/core';
import { IconFilter } from '@tabler/icons-react';
import { clsx } from 'clsx';

import classes from './Map.module.css';

interface MapFiltersContainerProps extends FlexProps {
  children: ReactNode;
}

export const MapFiltersContainer = (props: MapFiltersContainerProps): JSX.Element => {
  const { children, className, ...restProps } = props;
  return (
    <Flex className={clsx(classes.filtersContainer, className)} align='center' gap={8} {...restProps}>
      <IconFilter size='1.5rem' />
      {children}
    </Flex>);

};