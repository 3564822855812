import type BaseLayer from 'ol/layer/Base';

import { useCallback, useSyncExternalStore } from 'react';

export interface UseLayerVisibilityProps {
  layer: BaseLayer;
}
export const useLayerVisibility = (props: UseLayerVisibilityProps) => {
  const { layer } = props;

  const subscribe = useCallback(
    (onStoreChange: () => void) => {
      layer.on('change:visible', onStoreChange);
      return () => {
        layer.un('change:visible', onStoreChange);
      };
    },
    [layer],
  );
  const isVisible = useSyncExternalStore(
    subscribe,
    () => layer.getVisible(),
    () => false,
  );

  return { isVisible };
};
