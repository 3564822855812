import type { ComponentPropsWithoutRef } from 'react';

import { Button, type ButtonProps } from '@mantine/core';
import { IconPin, IconPolygon, IconCircle, IconSquare, IconRectangle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import { useDraw, type UseDrawProps, GEOMETRY_TYPE, type GeometryType } from '../hooks/useDraw';

const LeftSection = ({ type }: { type: GeometryType }) => {
  switch (type) {
    case GEOMETRY_TYPE.CIRCLE:
      return <IconCircle />;
    case GEOMETRY_TYPE.POINT:
      return <IconPin />;
    case GEOMETRY_TYPE.POLYGON:
      return <IconPolygon />;
    case GEOMETRY_TYPE.RECTANGLE:
      return <IconRectangle />;
    case GEOMETRY_TYPE.SQUARE:
      return <IconSquare />;
    default:
      return <></>;
  }
};

interface DrafterButtonProps extends Omit<ButtonProps, 'variant'>, Omit<ComponentPropsWithoutRef<'button'>, keyof ButtonProps> {
  options: UseDrawProps;
}

export const DrafterButton = (props: DrafterButtonProps): JSX.Element => {
  const { options, children, leftSection, onClick, ...restProps } = props;

  const { isActive, setActiveOnlyThis } = useDraw(options);
  const { t } = useTranslation();

  const handleClick: DrafterButtonProps['onClick'] = (e) => {
    setActiveOnlyThis();
    if (typeof onClick === 'function') onClick(e);
  };

  return (
    <Button
      {...restProps}
      variant={isActive ? 'filled' : 'default'}
      leftSection={leftSection || <LeftSection type={options.type} />}
      onClick={handleClick}
    >
      {children || t(`map.geometryTypes.${options.type}`)}
    </Button>
  );
};
