import type Feature from 'ol/Feature';
import type { SimpleGeometry } from 'ol/geom';
import type VectorSource from 'ol/source/Vector';

import { DEFAULT_THEME, alpha } from '@mantine/core';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style, { type StyleFunction } from 'ol/style/Style';

import { useVectorSource, type UseVectorLayerProps, type UseVectorSourceProps, useVectorLayer } from './useVector';

export const DEFAULT_COLOR = DEFAULT_THEME.colors.violet[5];
export const defaultStroke = new Stroke({
  color: DEFAULT_COLOR,
  width: 2,
});
export const defaultFill = new Fill({
  color: alpha(DEFAULT_COLOR, 0.5),
});
const defaultPolygonStyles = new Style({
  stroke: defaultStroke,
  fill: defaultFill,
});
const defaultStyles = {
  Circle: defaultPolygonStyles,
  Polygon: defaultPolygonStyles,
} as const;

const styleFunction: StyleFunction = (feature) => {
  const type = feature.getGeometry()?.getType();
  switch (type) {
    case 'Circle':
    case 'Polygon':
      return defaultStyles[type];
    default:
      return undefined;
  }
};

const selectedFill = new Fill({ color: alpha(DEFAULT_THEME.white, 0.25) });
const selectedStroke = new Stroke({ color: DEFAULT_THEME.colors.teal[9], width: 4 });
const selectedStyle = new Style({
  fill: selectedFill,
  stroke: selectedStroke,
});

export const getExcludedAreasSelectedStyles: StyleFunction = () => selectedStyle;

export interface UseExcludedAreasLayerProps {
  sourceOpts?: UseVectorSourceProps<Feature<SimpleGeometry>>;
  layerOpts?: Omit<UseVectorLayerProps<VectorSource<Feature<SimpleGeometry>>>, 'source'>;
}
export const useExcludedAreasLayer = (props?: UseExcludedAreasLayerProps) => {
  const { sourceOpts, layerOpts } = props || {};

  const { source } = useVectorSource(sourceOpts);
  const { layer } = useVectorLayer({
    ...layerOpts,
    source,
    style: styleFunction,
  });

  return { layer, source };
};
