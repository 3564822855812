import type { ReactNode } from 'react';

import { Box, type BoxProps } from '@mantine/core';
import { clsx } from 'clsx';

import classes from './controls.module.css';

interface ControlsBoxProps extends Omit<BoxProps, 'mod'> {
  id?: string;
  children?: ReactNode;
  align: 'top-left' | 'top' | 'top-right' | 'bottom-left' | 'bottom' | 'bottom-right';
  direction?: 'row' | 'column';
}

export const ControlsBox = (props: ControlsBoxProps): JSX.Element => {
  const { children, align, direction = 'column', className, id, ...restProps } = props;

  return (
    <Box
      id={id}
      mod={['align', { align }, 'direction', { direction }]}
      className={clsx(classes.controlsBox, className)}
      {...restProps}>

      {children}
    </Box>);

};