import type { ReadOptions } from 'ol/format/Feature';
import type { Geometry } from 'ol/geom';
import type RenderFeature from 'ol/render/Feature';

import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';

import { ALERT_TYPES } from '@/api/enums';
import { VEHICLE_VARIANT, VEHICLE_VARIANT_KEY } from '@/lib/OpenLayers/hooks/useVehicles';

export const getForkliftVariantForState = (state: string) => {
  switch (state) {
    case ALERT_TYPES.GROUP:
    case ALERT_TYPES.STOP:
      return VEHICLE_VARIANT.ERROR;
    case ALERT_TYPES.LOST:
      return VEHICLE_VARIANT.LOST;
    case ALERT_TYPES.CHARGING:
      return VEHICLE_VARIANT.CHARGING;

    case ALERT_TYPES.NONE:
    default:
      return VEHICLE_VARIANT.DEFAULT;
  }
};

export class GeoJSONForklifts extends GeoJSON {
  protected override readFeatureFromObject(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    object: any,
    options?: ReadOptions | undefined,
  ): Feature<Geometry> | RenderFeature | RenderFeature[] {
    const feature = super.readFeatureFromObject(object, options);

    if (feature instanceof Feature) {
      const state = feature.get('alert');
      feature.set(VEHICLE_VARIANT_KEY, getForkliftVariantForState(state));
    }

    return feature;
  }
}
