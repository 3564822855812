import { forwardRef, type ReactNode } from 'react';

import { Paper, type PaperProps, Text, Box, Divider, type BoxProps, createPolymorphicComponent } from '@mantine/core';
import cx from 'clsx';

import classes from './Map.module.css';

interface MapDrawerProps extends PaperProps {
  children: ReactNode;
}

export const MapDrawer = (props: MapDrawerProps): JSX.Element => {
  const { children, ...restProps } = props;

  return (
    <Paper component='aside' classNames={{ root: classes.drawerPaper }} {...restProps}>
      {children}
    </Paper>);

};

interface MapDrawerTitleProps {
  icon: ReactNode;
  title: ReactNode;
  id?: ReactNode;
  divider?: boolean;
}
export const MapDrawerTitle = (props: MapDrawerTitleProps) => {
  const { icon, title, id, divider = false } = props;
  return (
    <header>
      <Box className={classes.drawerTitle}>
        {icon}
        {id && <Text fw='bold'>#{id}</Text>}
        <Text fw='bold'>{title}</Text>
      </Box>
      {divider && <Divider my='xs' />}
    </header>);

};

interface MapDrawerBodyProps extends BoxProps {
  children: ReactNode;
}

export const MapDrawerBody = createPolymorphicComponent<'div', MapDrawerBodyProps>(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement, MapDrawerBodyProps>((props, ref) => {
    const { children, className, ...restProps } = props;
    return (
      <Box component='div' className={cx(classes.drawerBody, className)} {...restProps} ref={ref}>
        {children}
      </Box>);

  })
);
MapDrawerBody.displayName = 'MapDrawerBody';