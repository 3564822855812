import type Feature from 'ol/Feature';
import type { Point } from 'ol/geom';

import { useRef } from 'react';

import HeatmapLayer, { type Options as HeatmapLayerOptions } from 'ol/layer/Heatmap';

import { useVectorSource, type UseVectorSourceProps } from './useVector';

export interface UseHeatmapLayerProps extends HeatmapLayerOptions {}
export const useHeatmapLayer = (props?: HeatmapLayerOptions) => {
  const layerRef = useRef(new HeatmapLayer(props));

  return { layer: layerRef.current };
};

export interface UseHeatmapProps {
  sourceOpts?: UseVectorSourceProps<Feature<Point>>;
  layerOpts?: UseHeatmapLayerProps;
}
export const useHeatmap = (props: UseHeatmapProps) => {
  const { sourceOpts, layerOpts } = props;

  const { source } = useVectorSource(sourceOpts);
  const { layer } = useHeatmapLayer({
    ...layerOpts,
    source,
  });

  return { layer, source };
};
