import type Feature from 'ol/Feature';
import type { Point } from 'ol/geom';
import type VectorSource from 'ol/source/Vector';

import { DEFAULT_THEME } from '@mantine/core';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import RegularShape from 'ol/style/RegularShape';
import Stroke from 'ol/style/Stroke';
import Style, { type StyleFunction } from 'ol/style/Style';
import Text from 'ol/style/Text';

import { DEFAULT_FONT } from '../utils';

import { useVectorSource, type UseVectorLayerProps, type UseVectorSourceProps, useVectorLayer } from './useVector';

// #region STYLES
export const VEHICLE_ANGLE_KEY = 'angle';
export const VEHICLE_VARIANT_KEY = 'variant';

export const VEHICLE_VARIANT = {
  DEFAULT: 'DEFAULT',
  SELECTED: 'SELECTED',
  CHARGING: 'CHARGING',
  ERROR: 'ERROR',
  LOST: 'LOST',
} as const;
export type VehicleVariant = (typeof VEHICLE_VARIANT)[keyof typeof VEHICLE_VARIANT];

const colors = {
  [VEHICLE_VARIANT.CHARGING]: DEFAULT_THEME.colors.blue[8],
  [VEHICLE_VARIANT.DEFAULT]: DEFAULT_THEME.black,
  [VEHICLE_VARIANT.ERROR]: DEFAULT_THEME.colors.red[9],
  [VEHICLE_VARIANT.LOST]: DEFAULT_THEME.colors.gray[6],
  [VEHICLE_VARIANT.SELECTED]: DEFAULT_THEME.colors.teal[9],
} as const;

const getVehicleShape = ({ color = colors[VEHICLE_VARIANT.DEFAULT], rotation }: { color?: string; rotation: number }) => {
  return {
    box: new Circle({
      radius: 16,
      fill: new Fill({ color }),
    }),
    arrow: new RegularShape({
      points: 3,
      radius: 8,
      stroke: new Stroke({ color, width: 2 }),
      rotateWithView: true,
      angle: 0,
      displacement: [0, 23],
      rotation,
    }),
  };
};

export const getVehicleStyles = ({
  text,
  variant = VEHICLE_VARIANT.DEFAULT,
  rotation,
}: {
  text?: string;
  variant?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rotation?: any;
}) => {
  let parsedRotation = Number.parseFloat(rotation);
  if (isNaN(parsedRotation)) parsedRotation = 0.0;

  const shape = getVehicleShape({
    color: colors[variant as never] ?? colors[VEHICLE_VARIANT.DEFAULT],
    rotation: - parsedRotation + Number.parseFloat(globalThis.ENV.VEHICLE_ROTATION_DELTA),
  });

  return [
    new Style({
      image: shape.box,
      text: new Text({
        text,
        font: DEFAULT_FONT,
        fill: new Fill({ color: DEFAULT_THEME.white }),
      }),
    }),
    new Style({
      image: shape.arrow,
    }),
  ];
};

const vehicleStyleFunction: StyleFunction = (feature) =>
  getVehicleStyles({
    text: feature.getId()?.toString(),
    variant: feature.get(VEHICLE_VARIANT_KEY),
    rotation: feature.get(VEHICLE_ANGLE_KEY),
  });

export const getVehicleSelectedStyles: StyleFunction = (feature) =>
  getVehicleStyles({
    text: feature.getId()?.toString(),
    variant: VEHICLE_VARIANT.SELECTED,
    rotation: feature.get(VEHICLE_ANGLE_KEY),
  });

// #endregion

export interface UseVehiclesLayerProps {
  sourceOpts?: UseVectorSourceProps<Feature<Point>>;
  layerOpts?: Omit<UseVectorLayerProps<VectorSource<Feature<Point>>>, 'source' | 'style'>;
}
export const useVehiclesLayer = (props?: UseVehiclesLayerProps) => {
  const { sourceOpts, layerOpts } = props || {};

  const { source } = useVectorSource(sourceOpts);
  const { layer } = useVectorLayer({
    ...layerOpts,
    source,
    style: vehicleStyleFunction,
  });

  return { layer, source };
};
