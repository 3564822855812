import { type ReactNode } from 'react';

import { ActionIcon, Popover, type FloatingPosition } from '@mantine/core';
import { IconStack2 } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface LayersOptionsProps {
  children: ReactNode;
  position?: FloatingPosition;
}

export const LayersOptions = (props: LayersOptionsProps): JSX.Element => {
  const { children, position = 'left' } = props;

  const { t } = useTranslation();

  return (
    <Popover width={230} position={position} shadow='md'>
      <Popover.Target>
        <ActionIcon>
          <IconStack2 aria-label={t('map.mapOptions')} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>{children}</Popover.Dropdown>
    </Popover>
  );
};
