import { useCallback, useEffect, useRef, useSyncExternalStore } from 'react';

import Draw, { createBox, createRegularPolygon, type Options as DrawOptions, type DrawEvent } from 'ol/interaction/Draw';

import { useMap } from '../MapProvider';

export const GEOMETRY_TYPE = {
  CIRCLE: 'Circle',
  GEOMETRY_COLLECTION: 'GeometryCollection',
  LINE_STRING: 'LineString',
  LINEAR_RING: 'LinearRing',
  MULTI_LINE_STRING: 'MultiLineString',
  MULTI_POINT: 'MultiPoint',
  MULTI_POLYGON: 'MultiPolygon',
  POINT: 'Point',
  POLYGON: 'Polygon',
  RECTANGLE: 'Rectangle',
  SQUARE: 'Square',
} as const;

export type GeometryType = (typeof GEOMETRY_TYPE)[keyof typeof GEOMETRY_TYPE];

const getGeometryFunction = (type: GeometryType) => {
  switch (type) {
    case GEOMETRY_TYPE.RECTANGLE: {
      return { type: GEOMETRY_TYPE.CIRCLE, geometryFunction: createBox() };
    }
    case GEOMETRY_TYPE.SQUARE: {
      return { type: GEOMETRY_TYPE.CIRCLE, geometryFunction: createRegularPolygon(4) };
    }
    default: {
      return { type };
    }
  }
};

export const DRAW_EVENT_TYPE = {
  DRAWSTART: 'drawstart',
  DRAWEND: 'drawend',
  DRAWABORT: 'drawabort',
} as const;

export type DrawEventListener = (e: DrawEvent) => void;
export interface UseDrawProps extends Omit<DrawOptions, 'type'> {
  type: GeometryType;
  onDrawStart?: DrawEventListener;
  onDrawAbort?: DrawEventListener;
  onDrawEnd?: DrawEventListener;
}

export const useDraw = (props: UseDrawProps) => {
  const { type, onDrawStart, onDrawAbort, onDrawEnd, ...options } = props;

  const drawRef = useRef(new Draw({ ...options, ...getGeometryFunction(type) }));
  const { map } = useMap();

  const subscribe = useCallback((onStoreChange: () => void) => {
    const draw = drawRef.current;
    draw.on('change:active', onStoreChange);
    return () => {
      draw.un('change:active', onStoreChange);
    };
  }, []);
  const isActive = useSyncExternalStore(
    subscribe,
    () => drawRef.current.getActive(),
    () => false,
  );

  useEffect(() => {
    const draw = drawRef.current;
    draw.setActive(false);
    map.addInteraction(draw);
    return () => {
      map.removeInteraction(draw);
    };
  }, [map]);

  useEffect(() => {
    const draw = drawRef.current;
    if (onDrawStart) draw.on(DRAW_EVENT_TYPE.DRAWSTART, onDrawStart);
    return () => {
      if (onDrawStart) draw.un(DRAW_EVENT_TYPE.DRAWSTART, onDrawStart);
    };
  }, [onDrawStart]);

  useEffect(() => {
    const draw = drawRef.current;
    if (onDrawAbort) draw.on(DRAW_EVENT_TYPE.DRAWABORT, onDrawAbort);
    return () => {
      if (onDrawAbort) draw.un(DRAW_EVENT_TYPE.DRAWABORT, onDrawAbort);
    };
  }, [onDrawAbort]);

  useEffect(() => {
    const draw = drawRef.current;
    if (onDrawEnd) draw.on(DRAW_EVENT_TYPE.DRAWEND, onDrawEnd);
    return () => {
      if (onDrawEnd) draw.un(DRAW_EVENT_TYPE.DRAWEND, onDrawEnd);
    };
  }, [onDrawEnd]);

  const setActive = useCallback(() => drawRef.current.setActive(true), []);

  const setActiveOnlyThis = useCallback(() => {
    map.getInteractions().forEach((interaction) => {
      if (interaction instanceof Draw) interaction.setActive(false);
    });

    setActive();
  }, [map, setActive]);

  return { draw: drawRef.current, isActive, setActive, setActiveOnlyThis };
};
