import type { loader as posLoader } from '@/routes/logistic-places';
import type Feature from 'ol/Feature';
import type { SimpleGeometry } from 'ol/geom';
import type VectorSource from 'ol/source/Vector';

import { useFetcher } from '@remix-run/react';
import { useEffect, useState } from 'react';

import GeoJSON from 'ol/format/GeoJSON';

import { LOGISTIC_PLACES } from '@/api/enums';

const geoJsonFormat = new GeoJSON({
  dataProjection: undefined,
});

export const useFetcherLogisticPlaces = ({
  logisticPlacesSource,
}: {
  logisticPlacesSource: VectorSource<Feature<SimpleGeometry>>;
}) => {
  const [values, setValues] = useState<string[]>([
    LOGISTIC_PLACES.STOP_AREA,
    LOGISTIC_PLACES.PUT_AREA,
    LOGISTIC_PLACES.PICK_AREA,
    LOGISTIC_PLACES.CHARGE_POINT,
  ]);
  const fetcher = useFetcher<typeof posLoader>();
  const load = (type: string[]) => {
    fetcher.load(`/logistic-places?places=${type.join(',')}`);
    setValues(type);
  };

  useEffect(() => {
    const newPlaces = fetcher.data;
    if (newPlaces) {
      logisticPlacesSource.clear();
      logisticPlacesSource.addFeatures(geoJsonFormat.readFeatures(newPlaces) as Feature<SimpleGeometry>[]);
    }
  }, [fetcher.data, logisticPlacesSource]);

  return { load, values };
};
