export const AREA_STATES = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
} as const;
export type AreaStatesType = (typeof AREA_STATES)[keyof typeof AREA_STATES];

export const LOGISTIC_PLACES = {
  STOP_AREA: 'PARKING',
  CHARGE_POINT: 'CHARGING',
  PUT_AREA: 'PUT',
  PICK_AREA: 'PICK',
} as const;
export type LogisticPlacesType = (typeof LOGISTIC_PLACES)[keyof typeof LOGISTIC_PLACES];

export const ROUTE_TYPES = {
  ROUTE: 'ROUTE',
  STOP: 'STOP',
} as const;
export type RoutesType = (typeof ROUTE_TYPES)[keyof typeof ROUTE_TYPES];

export const ALERT_TYPES = {
  NONE: 'None',
  STOP: 'Stop',
  GROUP: 'Group',
  LOST: 'Lost',
  CHARGING: 'Charging',
} as const;
export type AlertsType = (typeof ALERT_TYPES)[keyof typeof ALERT_TYPES];

export const ALERT_CODES = {
  STOP: '5555',
  GROUP: '6666',
  COLLISION: '9999',
} as const;
export type AlertsCodes = (typeof ALERT_CODES)[keyof typeof ALERT_CODES];
