import type OLMap from 'ol/Map';

import { useEffect, useRef } from 'react';

import Transform, { type SelectEvent, type Options } from 'ol-ext/interaction/Transform';

export const TRANSFORM_EVENT_TYPE = {
  SELECT: 'select',
} as const;

export interface UseTransformProps extends Options {
  map: OLMap;
  onSelect?: (e: SelectEvent) => void;
}

export const useTransform = (props: UseTransformProps) => {
  const { map, onSelect, ...restProps } = props;

  const transformRef = useRef(
    new Transform({
      scale: true,
      stretch: true,
      rotate: true,
      translate: true,
      ...restProps,
    }),
  );

  useEffect(() => {
    const transform = transformRef.current;
    map.addInteraction(transform);
    //INFO: transform styles should be set after adding interaction to the map
    // rotation center indicator
    // transform.setStyle('rotate0', new Style({ text: new Text({ text: 'as', fill: new Fill({ color: 'blue' }) }) }));
    //rotation button
    // transform.setStyle('rotate', new Style({ image: new Icon({ src: rotateDot }) }));
    return () => {
      map.removeInteraction(transform);
    };
  }, [map]);

  useEffect(() => {
    const transform = transformRef.current;
    if (onSelect) transform.on(TRANSFORM_EVENT_TYPE.SELECT, onSelect);
    return () => {
      if (onSelect) transform.un(TRANSFORM_EVENT_TYPE.SELECT, onSelect);
    };
  }, [onSelect]);

  return { transform: transformRef.current };
};
