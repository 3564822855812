import type { FeatureLike } from 'ol/Feature';
import type Feature from 'ol/Feature';
import type { Geometry } from 'ol/geom';
import type RenderEvent from 'ol/render/Event';

import { useEffect, useRef } from 'react';

import { type Options as VectorLayerOptions } from 'ol/layer/BaseVector';
import VectorLayer from 'ol/layer/Vector';
import VectorSource, { VectorSourceEvent, type Options as VectorSourceOptions } from 'ol/source/Vector';

export interface UseVectorSourceProps<FeatureClass extends FeatureLike = Feature<Geometry>>
  extends VectorSourceOptions<FeatureClass> {
  onAddFeature?: (e: VectorSourceEvent) => void;
}
export const useVectorSource = <FeatureClass extends FeatureLike = Feature<Geometry>>(
  props?: UseVectorSourceProps<FeatureClass>,
) => {
  const { onAddFeature, ...options } = props || {};
  const sourceRef = useRef(new VectorSource(options));

  useEffect(() => {
    const vs = sourceRef.current;
    if (onAddFeature) vs.on('addfeature', onAddFeature);
    return () => {
      if (onAddFeature) vs.un('addfeature', onAddFeature);
    };
  }, [onAddFeature]);

  return { source: sourceRef.current };
};

export interface UseVectorLayerProps<SourceType extends VectorSource<Feature<Geometry>>> extends VectorLayerOptions<SourceType> {
  onPostrender?: (e: RenderEvent) => void;
}
export const useVectorLayer = <SourceType extends VectorSource<Feature<Geometry>>>(props?: UseVectorLayerProps<SourceType>) => {
  const { onPostrender, ...options } = props || {};
  const layerRef = useRef(new VectorLayer(options));

  useEffect(() => {
    const layer = layerRef.current;
    if (onPostrender) layer.on('postrender', onPostrender);
    return () => {
      if (onPostrender) layer.un('postrender', onPostrender);
    };
  }, [onPostrender]);

  return { layer: layerRef.current };
};
