import type Feature from 'ol/Feature';
import type { SimpleGeometry } from 'ol/geom';
import type VectorSource from 'ol/source/Vector';

import { DEFAULT_THEME } from '@mantine/core';
import Icon from 'ol/style/Icon';
import Style, { type StyleFunction } from 'ol/style/Style';

import { LOGISTIC_PLACES } from '@/api/enums';

import chargePointSrc from './charge-point.svg';
import parkingSrc from './parking.svg';
import pickSrc from './pick.svg';
import putSrc from './put.svg';
import { useVectorSource, type UseVectorLayerProps, type UseVectorSourceProps, useVectorLayer } from './useVector';

export const DEFAULT_COLOR = DEFAULT_THEME.colors.dark[6];

const defaultStyles = {
  [LOGISTIC_PLACES.CHARGE_POINT]: new Style({
    image: new Icon({
      src: chargePointSrc,
      color: DEFAULT_COLOR,
    }),
  }),
  [LOGISTIC_PLACES.PICK_AREA]: new Style({
    image: new Icon({
      src: pickSrc,
      color: DEFAULT_COLOR,
    }),
  }),
  [LOGISTIC_PLACES.PUT_AREA]: new Style({
    image: new Icon({
      src: putSrc,
      color: DEFAULT_COLOR,
    }),
  }),
  [LOGISTIC_PLACES.STOP_AREA]: new Style({
    image: new Icon({
      src: parkingSrc,
      color: DEFAULT_COLOR,
    }),
  }),
} as const;

const styleFunction: StyleFunction = (feature) => {
  const type = feature.get('type');

  if (typeof type !== 'string') return undefined;
  switch (type) {
    case LOGISTIC_PLACES.CHARGE_POINT:
    case LOGISTIC_PLACES.PICK_AREA:
    case LOGISTIC_PLACES.PUT_AREA:
    case LOGISTIC_PLACES.STOP_AREA: {
      return defaultStyles[type];
    }
    default:
      return undefined;
  }
};

export interface UseLogisticPlacesProps {
  sourceOpts?: UseVectorSourceProps<Feature<SimpleGeometry>>;
  layerOpts?: Omit<UseVectorLayerProps<VectorSource<Feature<SimpleGeometry>>>, 'source'>;
}
export const useLogisticPlaces = (props?: UseLogisticPlacesProps) => {
  const { sourceOpts, layerOpts } = props || {};

  const { source } = useVectorSource(sourceOpts);
  const { layer } = useVectorLayer({
    ...layerOpts,
    declutter: true,
    source,
    style: styleFunction,
  });

  return { layer, source };
};
