import type BaseLayer from 'ol/layer/Base';

import type { MouseEventHandler, ReactNode } from 'react';

import { ActionIcon, Tooltip, type ActionIconProps } from '@mantine/core';

import { useLayerVisibility } from '../hooks/useLayerVisibility';

export interface LayerBtnProps extends Omit<ActionIconProps, 'variant'> {
  label: ReactNode;
  isLayerVisible: boolean;
  iconOn?: ReactNode;
  iconOff?: ReactNode;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const LayerBtn = (props: LayerBtnProps): JSX.Element => {
  const { label, isLayerVisible, children, iconOn, iconOff, ...restProps } = props;

  return (
    <Tooltip label={label} withArrow>
      <ActionIcon variant={isLayerVisible ? 'filled' : 'light'} {...restProps}>
        {isLayerVisible ? iconOn : iconOff}
        {children}
      </ActionIcon>
    </Tooltip>
  );
};

export interface LayerVisibilityBtnProps extends Omit<LayerBtnProps, 'isLayerVisible'> {
  layer: BaseLayer;
  children?: ReactNode;
}

export const LayerVisibilityBtn = (props: LayerVisibilityBtnProps): JSX.Element => {
  const { layer, children, onClick, ...restProps } = props;

  const { isVisible } = useLayerVisibility({ layer });

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (layer.getVisible()) {
      layer.setVisible(false);
    } else {
      layer.setVisible(true);
    }
    if (typeof onClick === 'function') onClick(e);
  };

  return (
    <LayerBtn {...restProps} isLayerVisible={isVisible} onClick={handleOnClick}>
      {children}
    </LayerBtn>
  );
};
