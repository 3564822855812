import { type ComponentProps, useEffect, type ReactNode } from 'react';

import { useMap } from './MapProvider';

interface MapComponentProps extends ComponentProps<'div'> {
  children?: ReactNode;
}

export const MapComponent = (props: MapComponentProps): JSX.Element => {
  const { id, children, ...restProps } = props;
  const { map } = useMap();

  useEffect(() => {
    map.setTarget(id);

    return () => {
      map.setTarget(undefined);
    };
  }, [map, id]);

  return (
    <div id={id} {...restProps}>
      {children}
    </div>
  );
};
