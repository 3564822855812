import type OLMap from 'ol/Map';

import { useContext, createContext } from 'react';

interface MapContextProps {
  readonly map: OLMap;
  readonly mapTargetId: string;
}

export const MapContext = createContext<MapContextProps | undefined>(undefined);
MapContext.displayName = 'MapContext';

export const MapProvider = MapContext.Provider;

export const useMap = () => {
  const ctx = useContext(MapContext);

  if (!ctx) {
    throw new Error('Component beyond MapContext.');
  }

  return ctx;
};
