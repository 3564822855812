import type { ReadOptions, WriteOptions } from 'ol/format/Feature';
import type { Geometry } from 'ol/geom';
import type RenderFeature from 'ol/render/Feature';
import type { FlatStyle } from 'ol/style/flat';

import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import { Fill, Stroke, Style } from 'ol/style';

export class GeoJSONStyled extends GeoJSON {
  protected override readFeatureFromObject(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    object: any,
    options?: ReadOptions | undefined,
  ): Feature<Geometry> | RenderFeature | RenderFeature[] {
    const feature = super.readFeatureFromObject(object, options);
    if (!(feature instanceof Feature)) {
      return feature;
    }

    const flatStyle = feature.get('style');
    if (flatStyle !== null && typeof flatStyle === 'object' && !Array.isArray(flatStyle)) {
      feature.setStyle(
        new Style({
          stroke: new Stroke({
            color: typeof flatStyle['stroke-color'] === 'string' ? flatStyle['stroke-color'] : undefined,
            width: typeof flatStyle['stroke-width'] === 'number' ? flatStyle['stroke-width'] : undefined,
          }),
          fill: new Fill({
            color: typeof flatStyle['fill-color'] === 'string' ? flatStyle['fill-color'] : undefined,
          }),
        }),
      );
    }

    return feature;
  }

  override writeFeatureObject(feature: Feature<Geometry>, options?: WriteOptions) {
    const object = super.writeFeatureObject(feature, options);

    const style = feature.getStyle();
    if (style instanceof Style) {
      const flatStyle: FlatStyle = {};

      const stroke = style.getStroke();
      if (stroke) {
        flatStyle['stroke-color'] = stroke.getColor()?.toString();
        flatStyle['stroke-width'] = stroke.getWidth();
      }

      const fill = style.getFill();
      if (fill) {
        flatStyle['fill-color'] = fill.getColor()?.toString() ?? undefined;
      }

      object.properties = { ...object.properties, style: flatStyle };
    }

    return object;
  }
}
