import { Stack, Switch, type SwitchGroupProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { LOGISTIC_PLACES } from '@/api/enums';

interface LogisticPlacesProps extends Omit<SwitchGroupProps, 'children'> {}

export const LogisticPlaces = (props: LogisticPlacesProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Switch.Group aria-label={t('map.logisticPlaces.title')} {...props}>
      <Stack gap='xs'>
        <Switch value={LOGISTIC_PLACES.STOP_AREA} label={t('map.logisticPlaces.stopArea')} />
        <Switch value={LOGISTIC_PLACES.PUT_AREA} label={t('map.logisticPlaces.putArea')} />
        <Switch value={LOGISTIC_PLACES.PICK_AREA} label={t('map.logisticPlaces.pickArea')} />
        {/* <Switch value={LOGISTIC_PLACES.START_AREA} labelPosition='left' label={t('map.logisticPlaces.start')} /> */}
        <Switch value={LOGISTIC_PLACES.CHARGE_POINT} label={t('map.logisticPlaces.chargePoint')} />
      </Stack>
    </Switch.Group>
  );
};
