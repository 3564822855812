import { useEffect, useRef } from 'react';

import Select, { type Options as SelectOptions, type SelectEvent } from 'ol/interaction/Select';

export const SELECT_EVENT_TYPE = {
  SELECT: 'select',
} as const;

export type SelectEventListener = (e: SelectEvent) => void;
export interface UseSelectProps extends Omit<SelectOptions, 'type'> {
  onSelect?: SelectEventListener;
}

export const useSelect = (props: UseSelectProps) => {
  const { onSelect, ...options } = props;

  const selectRef = useRef(new Select(options));

  useEffect(() => {
    const select = selectRef.current;
    if (onSelect) select.on(SELECT_EVENT_TYPE.SELECT, onSelect);
    return () => {
      if (onSelect) select.un(SELECT_EVENT_TYPE.SELECT, onSelect);
    };
  }, [onSelect]);

  return { select: selectRef.current };
};
