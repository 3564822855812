import { ActionIcon } from '@mantine/core';
import { IconPlus, IconMinus } from '@tabler/icons-react';
import { easeOut } from 'ol/easing';
import { useTranslation } from 'react-i18next';

import { useMap } from '../MapProvider';

export const Zoom = (): JSX.Element => {
  const { map } = useMap();
  const { t } = useTranslation();

  const setZoomByDelta = (delta: number) => {
    const view = map.getView();
    if (!view) {
      // the map does not have a view, so we can't act upon it
      return;
    }

    const currentZoom = view.getZoom();
    if (currentZoom === undefined) {
      return;
    }

    const newZoom = view.getConstrainedZoom(currentZoom + delta);
    if (view.getAnimating()) {
      view.cancelAnimations();
    }
    view.animate({
      zoom: newZoom,
      duration: 250,
      easing: easeOut,
    });
  };

  return (
    <>
      <ActionIcon aria-label={t('map.zoomIn')} color='secondary' onClick={() => setZoomByDelta(1)}>
        <IconPlus />
      </ActionIcon>
      <ActionIcon aria-label={t('map.zoomOut')} color='secondary' onClick={() => setZoomByDelta(-1)}>
        <IconMinus />
      </ActionIcon>
    </>
  );
};
